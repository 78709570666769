const BoConfig = {
    sidebar: [
        {name:"Dashboard",link:"BoDashboard",icon:"fas fa-tachometer-alt"},

        {name:"Customers",icon:'fas fa-users',link:'BoCustomers'},
        {name:"Member", icon:"fas fa-users",childs:[
            {name:"Account",link:"BoMember"},
            {name:"Product",link:"MemberProduct"},
            {name:"Monthly Fee",link:"MonthlyFee"},
        ]},
        {name:"Catalogue", icon:"fas fa-tag",childs:[
            {name:"Product Category",link:"ProductCategory"},
            {name:"Product List",link:"Product"},
        ]},

        {name:"Sales", icon:"fas fa-shopping-cart",childs:[
            {name:"Transaction",link:"Transaction"},
            {name:"Received",link:"Received"},
        ]},

        {name:"Web Content",divider:'Content',icon:'',link:''},
        {name:"Home",link:"Home",icon:"fas fa-home"},
        {name:"About",link:"About",icon:"fas fa-book"},
        {name:"Blog",link:"Blog",icon:"fas fa-newspaper"},
        {name:"Contact",link:"Contact",icon:"fas fa-envelope"},
        {name:"Testimoni",link:"Testimoni",icon:"fas fa-file"},
        {name:"Brand",link:"Brand",icon:"fas fa-tag"},

        {name:"Settings",divider:'Settings',icon:'',link:''},
        {name:"Settings", icon:"fas fa-cog",childs:[
            {name:"SEO",link:"SettingSeo"},
            {name:"Legal page",link:"SettingLegalPage"},
            {name:"Static Content",link:"StaticContent"},
            {name:"Bank",link:"MrBank"},
            {name:"Artisan Bank",link:"ArtisanBank"},
        ]},
        {name:"User Management", icon:"fas fa-users",childs:[
            {name:"Users",link:"BoUsers"},
            {name:"User Level",link:"BoUserLevel"},
        ]},
    ],
}
export default BoConfig