<script>
import GlobalVue from '@root/helper/Global.vue';
export default {
  name: "NavBar",
  extends: GlobalVue,
  data(){
    return{
      notif:{}
    }    
  },
  mounted(){
    window.nav = this
  },
  methods:{
    logout(){
            this.page.logout()
        },
    hideLogo(){
      if($('#lerge-logo').hasClass( "hide-menu" )){
        $('#lerge-logo').removeClass( "hide-menu" )
        $('#small-logo').addClass( "hide-menu" )
        $('body').removeClass('mini-sidebar')
      }
      else{
        $('#lerge-logo').addClass( "hide-menu" )
        $('#small-logo').removeClass( "hide-menu" )
        $('body').addClass('mini-sidebar')
      }
    },
    showSidebar(){
      $("body").toggleClass("show-sidebar");
      $(".wrapper-overlay").toggle();
      $(".nav-toggler i").toggleClass("ti-menu ti-close");
    }
  }
};
</script>


<template>
    <header class="topbar">
      <nav class="navbar top-navbar navbar-expand-md navbar-dark">
          <!-- ============================================================== -->
          <!-- Logo -->
          <!-- ============================================================== -->
          <div class="navbar-header  text-center">
              <a class="navbar-brand" >
                  <img :src="assets('bo_images','logo-artisan-putih.png')"  alt="homepage" id="lerge-logo" class="dark-logo" :class="isMobile ? 'hide-menu':''" />
                  <img :src="assets('bo_images','logo-artisan-part.png')" height="50px" alt="homepage" id="small-logo" class="dark-logo" :class="!isMobile ? 'hide-menu':''" />
                 </a>
          </div>
          <!-- ============================================================== -->
          <!-- End Logo -->
          <!-- ============================================================== -->
          <div class="navbar-collapse">
              <!-- ============================================================== -->
              <!-- toggle and nav items -->
              <!-- ============================================================== -->
              <ul class="navbar-nav">
                  <!-- This is  -->
                  <li class="nav-item" @click="showSidebar"> <a class="nav-link nav-toggler d-block d-md-none waves-effect waves-dark" href="javascript:void(0)"><i class="ti-menu"></i></a> </li>
                  <li class="nav-item" @click="hideLogo"> <a class="nav-link sidebartoggler d-none d-lg-block d-md-block waves-effect waves-dark" href="javascript:void(0)"><i class="icon-menu"></i></a> </li>
                  <!-- ============================================================== -->
                  <!-- Search -->
                  <!-- ============================================================== -->
                  <!-- <li class="nav-item">
                      <form class="app-search d-none d-md-block d-lg-block">
                          <input type="text" class="form-control" placeholder="Search & enter">
                      </form>
                  </li> -->
              </ul>
          </div>
      </nav>
  </header>
</template>
